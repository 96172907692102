<template lang="pug">
div(ref='menu' class='relative')
  button(
    type='button'
    class='block -ml-2 p-2 rounded-full'
    aria-expanded='false'
    aria-haspopup='true'
    @click='isMenuOpen = !isMenuOpen'
  )
    img(src='/images/ellipsisVertical.svg' height='20' width='20' alt='Customer menu')

  transition(
    enter-active-class='transition ease-out duration-100'
    enter-from-class='transform opacity-0 scale-95'
    enter-to-class='opacity-100 scale-100'
    leave-active-class='transition ease-in duration-75'
    leave-from-class='opacity-100 scale-100'
    leave-to-class='transform opacity-0 scale-95'
  )
    div(
      v-if='isMenuOpen'
      class='absolute right-0 z-10 mt-0 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-lighter focus:outline-none'
      role='menu'
      tabindex='-1'
    )
      div(class='py-1')
        button(
          v-if='showPaylinkOption'
          class='block w-full text-left px-4 py-2 text-sm text-gray-darker enabled:hover:bg-gray-lightest enabled:hover:text-gray-darkest enabled:hover:font-bold disabled:opacity-30'
          type='button'
          :disabled='cart.state.value.itemCount === 0'
          @click='sendPaylink'
        ) Send Paylink
        button(
          class='block w-full text-left px-4 py-2 text-sm text-gray-darker hover:bg-gray-lightest hover:text-gray-darkest hover:font-bold'
          type='button'
          @click='emitEditEvent'
        ) Edit
        button(
          class='block w-full text-left px-4 py-2 text-sm text-gray-darker hover:bg-gray-lightest hover:text-gray-darkest hover:font-bold'
          type='button'
          @click='deleteCustomer'
        ) Delete
</template>

<script setup lang="ts">
const customerManager = useCustomerManager()
const cart = useCart()

const { customer } = defineProps<{
  customer: Customer
  showPaylinkOption?: boolean
}>()

const emit = defineEmits<{
  edit: [id: Customer]
}>()

const menu = ref()
const isMenuOpen = ref(false)

onClickOutside(menu, () => {
  isMenuOpen.value = false
})

function sendPaylink() {
  isMenuOpen.value = false

  customerManager.openPaylinkModal()
}

function emitEditEvent() {
  isMenuOpen.value = false
  emit('edit', customer)
}

function deleteCustomer() {
  customerManager.deleteCustomer(customer.id)
}
</script>
