<template lang="pug">
FormKit(type='form' :actions='false' @submit='saveCustomer')
  template(#default='{ state }')
    BaseFormInput(validation='required|length:2,32' label='Customer Name' name='name' :value='customer?.name')

    BaseFormSelect(
      label='Lead Source'
      name='leadSource'
      :value='customer?.leadSource'
      :options='leadSourceOptions'
      placeholder='Select Lead Source'
      validation='required'
    )

    div(class='flex justify-end gap-x-2 pt-4')
      BaseButton(color='WHITE' size='SMALL' type='button' :is-disabled='state.loading' @click='closeForm') Cancel
      BaseButton(color='ACTION' size='SMALL' type='submit' :is-disabled='!state.valid' :is-waiting='state.loading') Save Cart
</template>

<script setup lang="ts">
const { $cognito } = useNuxtApp()
const customerManager = useCustomerManager()
const notifications = useNotifications()

const { customer } = defineProps<{
  customer?: Customer
}>()

const emit = defineEmits(['close'])

const isLoading = ref(false)

const leadSourceOptions = computed(() => {
  const onlineSalesOptions = [
    { label: 'Chat', value: 'CHAT' },
    { label: 'Phone', value: 'PHONE' },
  ]

  // TODO: Add once available on the backend. Netsuite is not setup to handle this value yet
  // so Mike Tracy can't make this option available yet
  // const retailSalesOptions = [{ label: 'Retail', value: 'RETAIL' }]

  return [
    ...($cognito.isSalesAgent() ? onlineSalesOptions : []),
    // ...($cognito.isRetailSalesAgent() ? retailSalesOptions : []),
  ]
})

async function saveCustomer(data) {
  if (isLoading.value) return
  isLoading.value = true

  try {
    if (customer) {
      await customerManager.updateCustomer(customer.id, {
        name: data.name.trim(),
        leadSource: data.leadSource,
      })
    } else {
      await customerManager.createCustomer(data.name, data.leadSource)
    }

    closeForm()
  } catch (error) {
    notifications.addError(customer ? 'Unable to update customer' : 'Unable to create customer', error?.data?.msg)
  } finally {
    isLoading.value = false
  }
}

function closeForm() {
  emit('close')
}
</script>
