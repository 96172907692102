<template lang="pug">
div(class='sticky top-0 z-50 bg-gray-lighter border-b-4 border-primary')
  div(class='relative flex h-14 px-4 items-center justify-between')
    CustomerManager

    //- User dropdown
    div(ref='userOptions' class='relative inline-block text-left')
      button(
        type='button'
        class='flex items-center justify-center w-8 h-8 rounded-full bg-primary text-dark border border-primary px-3 py-2 text-sm font-bold hover:border-black focus:outline-none'
        aria-expanded='true'
        aria-haspopup='true'
        @click='isOptionsMenuOpen = !isOptionsMenuOpen'
      )
        | {{ abbreviatedUser }}

      transition(
        enter-active-class='transition ease-out duration-100'
        enter-from-class='transform opacity-0 scale-95'
        enter-to-class='opacity-100 scale-100'
        leave-active-class='transition ease-in duration-75'
        leave-from-class='opacity-100 scale-100'
        leave-to-class='transform opacity-0 scale-95'
      )
        div(
          v-if='isOptionsMenuOpen'
          class='absolute right-0 z-30 mt-1 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-lighter focus:outline-none'
          role='menu'
          tabindex='-1'
        )
          div(class='py-1')
            UniversalLink(
              link='/account'
              class='block w-full text-left px-4 py-2 text-sm text-gray-darker hover:bg-gray-lightest hover:text-gray-darkest hover:font-bold'
            ) Profile

            button(
              class='block w-full text-left px-4 py-2 text-sm text-gray-darker hover:bg-gray-lightest hover:text-gray-darkest hover:font-bold'
              type='button'
              @click='logout'
            ) Sign out
</template>

<script setup lang="ts">
const { $cognito } = useNuxtApp()

const username = ref('')

const userOptions = ref()
const isOptionsMenuOpen = ref(false)

onClickOutside(userOptions, () => {
  isOptionsMenuOpen.value = false
})

const abbreviatedUser = computed(() => {
  return username.value.slice(0, 2).toUpperCase()
})

onMounted(() => {
  username.value = $cognito.getUsername()
})

function logout() {
  $cognito.logout()
}
</script>
