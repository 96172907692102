<template lang="pug">
div
  //- If we don't have a customer then we are on the main tab
  BaseButton(v-if='!customerManager.customer.value' size='XSMALL' @click='openCustomerManager') View Customers

  //- If we have a customer then we are on the customer tab and want to only show the current customer
  div(v-else class='flex items-center gap-x-3')
    p(class='font-bold') {{ customerManager.customer.value.name }}
    CustomerManagerLeadSource(:lead-source='customerManager.customer.value.leadSource')
    CustomerManagerCustomerMenu(:customer='customerManager.customer.value' show-paylink-option @edit='editCustomer')

  Overlay(unique-key='customerManager' title='Customer Manager' modal-width='WIDE' @closed='close')
    template(#noPadding)
      div(class='relative')
        template(v-if='showCustomerEditor')
          div(class='p-5')
            CustomerManagerCustomerForm(:customer='customerToEdit' @close='close')

        template(v-else)
          div(class='flex flex-col justify-center')
            div(v-if='customerManager.customers.value.length === 0' class='w-full text-center pt-6 mb-2')
              p(class='font-bold mb-1') No Customers
              p(class='text-sm') Get started by creating a new customer

            ul(v-else class='divide-y divide-gray-light px-4')
              template(v-for='customer in customerManager.customers.value' :key='customer.id')
                li(class='min-h-20 flex items-center')
                  div(class='flex-1 group flex items-center justify-between gap-x-6 py-4')
                    div
                      p(class='font-bold text-sm') {{ customer.name }}

                      div(v-if='customer.fitmentData?.make' class='mt-1 flex items-center gap-x-2 text-gray-dark text-xs')
                        p(class='whitespace-nowrap')
                          | {{ customer.fitmentData.year }} {{ customer.fitmentData.make }} {{ customer.fitmentData.model }} {{ customer.fitmentData.bed }} {{ customer.fitmentData.body }} {{ customer.fitmentData.engine }}

                    div(class='flex flex-none items-center gap-x-4')
                      CustomerManagerLeadSource(:lead-source='customer.leadSource')

                      //- Using UniversalLink to open the cart in a new window/tab. Can't use BaseButton because it doesn't support open-new-window
                      UniversalLink(
                        :link='`/?customer=${customer.id}`'
                        class='px-3 py-1 border border-gray rounded text-sm text-gray font-bold outline-none leading-snug hover:bg-action hover:text-white hover:border-action'
                        open-new-window
                      ) View Cart

                      CustomerManagerCustomerMenu(:customer='customer' @edit='editCustomer')

            div(v-if='customerManager.customers.value.length < 8' class='mt-4 pb-6 text-center')
              BaseButton(
                color='ACTION'
                size='SMALL'
                type='button'
                has-custom-padding
                class='px-3 py-2.5'
                @click='createCustomer'
              )
                | Create New Customer

  Overlay(unique-key='customerManagerPaylink' title='Send Paylink' modal-width='WIDE' @closed='close')
    template(#default)
      CustomerManagerPaylinkForm(@close='close')
</template>

<script setup lang="ts">
const { $overlay } = useNuxtApp()
const fitmentStore = useFitmentStore()
const fitmentDisplayStore = useFitmentDisplayStore()
const customerManager = useCustomerManager()
const cart = useCart()

onMounted(() => {
  const customer = customerManager.customer.value

  if (!customer) return

  // This will stop the fitment cookie from updating
  fitmentStore.disableCookie()

  // Clear the fitment
  fitmentDisplayStore.clearFitment()

  // If we have fitment data then update the fitment
  if (customer.fitmentData) {
    fitmentStore.update(customer.fitmentData)
  }

  // Re-setup the fitment display
  fitmentDisplayStore.setupFitment()
  fitmentDisplayStore.initSelects()
})

// Watch the customer, if we do not have a customer,
// then it was deleted from the state, so close the browser tab
watch(customerManager.customer, (newVal) => {
  if (!newVal) window.close()
})

// Watch the fitment store and if it changes then update the customers fitment
watch(fitmentStore.$state, (newVal) => {
  if (customerManager.customer.value) {
    customerManager.updateCustomer(customerManager.customer.value.id, { fitmentData: newVal })
  }
})

// Watch the cart state to see when the cart id changes. If it does then update the customer
watch(
  () => cart.state.value.id,
  async (newVal) => {
    if (customerManager.customer.value) {
      await customerManager.updateCustomer(customerManager.customer.value.id, {
        cartId: newVal,
        leadSource: customerManager.customer.value.leadSource,
      })

      // If the cart id is empty then the cart was just checked out so we should make them a new one
      if (!newVal) {
        await cart.init()
      } else {
        // If the cart id has changed then refresh the cart to reflect that the lead source was set on the newly created customer cart.
        await cart.refresh()
      }
    }
  }
)

const showCustomerEditor = ref(false)
const customerToEdit = ref<Customer>()

async function openCustomerManager() {
  $overlay.open('customerManager')
}

async function close() {
  // If we have a customer then we are on a customer tab and should close the modal completely
  // Since the main customer manager should only be available on the agents tab
  if (customerManager.customer.value) {
    await $overlay.close()
  }

  showCustomerEditor.value = false
  customerToEdit.value = undefined
}

function createCustomer() {
  // Make sure we clear the customer to edit before we try to make a new customer
  customerToEdit.value = undefined

  showCustomerEditor.value = true
}

function editCustomer(customer: Customer) {
  customerToEdit.value = customer

  showCustomerEditor.value = true

  // If we have a customer then we are on a customer tab and the modal won't be open
  // So we need to open it to show the form
  if (customerManager.customer.value) openCustomerManager()
}
</script>
